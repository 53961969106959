import React from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

export default () => (
  <Grid
    container
    spacing={2}
    alignItems="center"
    justify="center"
    direction="column"
    style={{ height: "100%", width: "100%" }}
  >
    <Grid item>
      <Typography variant="h4">
        Connecting to the socket. Please wait.
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="h5">
        If you see this message for more than 10 seconds, refresh the page.
      </Typography>
    </Grid>
  </Grid>
);
