import React from "react";
import { useSelector } from "react-redux";
import { TEAM_NUMBER_SIZE } from "../../config";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export default () => {
  const data = useSelector(state => state.station.data);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justify="center"
      direction="column"
      style={{ height: "100%", width: "100%" }}
    >
      <Grid item>
        <Typography variant="h4">Team</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h3" style={{ fontSize: TEAM_NUMBER_SIZE }}>
          {data.team}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h2">Please wait at this station</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h4">{data.message}</Typography>
      </Grid>
    </Grid>
  );
};
