import React from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

export default () => (
  <Grid
    container
    spacing={2}
    alignItems="center"
    justify="center"
    direction="column"
    style={{ height: "100%", width: "100%" }}
  >
    <Grid item>
      <Typography variant="h4">
        Another user has logged in as this station.
      </Typography>
    </Grid>
  </Grid>
);
