import Action from "./types"
import send from "../send"
import packets from "../packets"

const updateSubmitEnabled = () => (dispatch, getState) => {
  const number = getState().gatekeeper.number
  dispatch({
    type: Action.GATEKEEPER_UPDATE_SUBMIT_ENABLED,
    value: !isNaN(parseInt(number)) && parseInt(number) > 0,
  })
}

export const changeNumber = number => dispatch => {
  dispatch({ type: Action.GATEKEEPER_UPDATE_NUMBER, value: number })
  dispatch(updateSubmitEnabled())
}

export const updateTaskA = checked => ({
  type: Action.GATEKEEPER_UPDATE_TASK_A,
  value: checked,
})

export const updateTaskB = checked => ({
  type: Action.GATEKEEPER_UPDATE_TASK_B,
  value: checked,
})

export const updateTaskC = checked => ({
  type: Action.GATEKEEPER_UPDATE_TASK_C,
  value: checked,
})

export const updateFullTrack = checked => ({
  type: Action.GATEKEEPER_UPDATE_FULL_TRACK,
  value: checked,
})

export const updateConfirmSuccessSnackbar = open => ({
  type: Action.GATEKEEPER_UPDATE_CONFIRM_SUCCESS_SNACKBAR,
  value: open,
})

export const updateConfirmFailSnackbar = open => ({
  type: Action.GATEKEEPER_UPDATE_CONFIRM_FAIL_SNACKBAR,
  value: open,
})

export const gatekeeperBack = () => dispatch => {
  dispatch(changeNumber(""))
  dispatch({ type: Action.GATEKEEPER_UPDATE_PHASE, value: "request" })
}

export const sendGatekeeperRequest = () => (dispatch, getState) => {
  const state = getState().gatekeeper
  if (!state.submitEnabled) {
    return
  }
  send(packets.gatekeeperRequestTeam, {
    number: state.number,
  })
}

export const sendGatekeeperConfirm = () => (dispatch, getState) => {
  const state = getState().gatekeeper
  if (!state.submitEnabled) {
    return
  }
  send(packets.gatekeeperConfirm, {
    number: state.number,
    taskA: state.taskA,
    taskB: state.taskB,
    taskC: state.taskC,
    fullTrack: state.fullTrack,
  })
}

export const handleGatekeeperResponse = packet => (dispatch, getState) => {
  dispatch({ type: Action.GATEKEEPER_UPDATE_RESPONSE, value: packet })
  dispatch(updateTaskA(!packet.team.subtaskA.complete))
  dispatch(updateTaskB(!packet.team.subtaskB.complete))
  dispatch(updateTaskC(!packet.team.subtaskC.complete))
  dispatch(updateFullTrack(!packet.team.fullTrack.complete))
  dispatch({ type: Action.GATEKEEPER_UPDATE_PHASE, value: "confirm" })
}

export const handleGatekeeperComplete = packet => (dispatch, getState) => {
  dispatch({
    type: packet.success
      ? Action.GATEKEEPER_UPDATE_CONFIRM_SUCCESS_SNACKBAR
      : Action.GATEKEEPER_UPDATE_CONFIRM_FAIL_SNACKBAR,
    value: true,
  })
  dispatch(changeNumber(""))
  dispatch({ type: Action.GATEKEEPER_UPDATE_PHASE, value: "request" })
}
