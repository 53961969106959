import { combineReducers } from "redux";
import Action from "../actions/types";
import { integer, boolean, string, object, list } from "./generators";

export default combineReducers({
  tab: integer(Action.ADMIN_UPDATE_TAB),
  uploadFailSnackbar: boolean(Action.ADMIN_UPDATE_UPLOAD_FAIL_SNACKBAR),
  uploadSuccessSnackbar: boolean(Action.ADMIN_UPDATE_UPLOAD_SUCCESS_SNACKBAR),
  uploadMessage: string(Action.ADMIN_UPDATE_UPLOAD_MESSAGE),
  team: list(Action.ADMIN_UPDATE_TEAM),
  requestNumber: string(Action.ADMIN_UPDATE_REQUEST_NUMBER),
  data: object(Action.ADMIN_UPDATE_DATA, {
    status: [],
    users: [],
    zonesA: [],
    qualifyA: [],
    zonesB: [],
    qualifyB: [],
    zonesC: [],
    qualifyC: [],
    zonesD: [],
    qualifyD: [],
    disabledStations: [],
    disabledFullStations: [],
    stations: 0,
    fullStations: 0,
    lateQueue: true,
    testData: false
  })
});
