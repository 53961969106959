import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TEAM_NUMBER_SIZE } from "../../config";
import {
  updateDemoPhase,
  sendDismissTeam,
  updateDismissalNote,
  updateAllowRetry
} from "../../actions/station";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

export default () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.station.data);
  const note = useSelector(state => state.station.dismissalNote);
  const enabled = useSelector(state => state.station.dismissEnabled);
  const retry = useSelector(state => state.station.allowRetry);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justify="center"
      direction="column"
      style={{ height: "100%", width: "100%" }}
    >
      <Grid item>
        <Typography variant="h4">Team</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h3" style={{ fontSize: TEAM_NUMBER_SIZE }}>
          {data.team}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h2">Team Dismissal Form</Typography>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" justify="center" spacing={1}>
          <Grid item>
            <TextField
              label="Dismissal Note"
              value={note}
              onChange={event =>
                dispatch(updateDismissalNote(event.target.value))
              }
              margin="normal"
              variant="outlined"
              style={{ minWidth: 400 }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2} alignItems="center" justify="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => dispatch(updateDemoPhase("entry"))}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              disabled={!enabled}
              onClick={() => dispatch(sendDismissTeam(note, retry))}
            >
              Dismiss Team
            </Button>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={retry}
                  onChange={event =>
                    dispatch(updateAllowRetry(event.target.checked))
                  }
                  color="primary"
                />
              }
              label="Allow Retry"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
