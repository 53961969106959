import React, { useState, useRef, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  sendHandshake,
  changePassword,
  changeRole,
  changeStation,
} from "../../actions/login"
import Role from "../../roles"

import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import { useEnter } from "../../hooks"

export default () => {
  const dispatch = useDispatch()
  const state = useSelector(state => state.login)

  const roleLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)

  useEffect(() => {
    setLabelWidth(roleLabel.current.offsetWidth)
  }, [])
  useEnter(() => dispatch(sendHandshake()))

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={1}
      style={{ width: "100%", height: "100%" }}
    >
      <Grid item>
        <Typography variant="h1" component="h2">
          Project 4 Final Demo
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6">Select your assigned role</Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <FormControl variant="outlined" style={{ minWidth: 120 }}>
              <InputLabel ref={roleLabel}>Role</InputLabel>
              <Select
                value={state.role}
                onChange={event => dispatch(changeRole(event.target.value))}
                labelWidth={labelWidth}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value={Role.STATION_A}>Subtask Station A</MenuItem>
                <MenuItem value={Role.STATION_B}>Subtask Station B</MenuItem>
                <MenuItem value={Role.STATION_C}>Subtask Station C</MenuItem>
                <MenuItem value={Role.STATION_FULL}>Full Track</MenuItem>
                <MenuItem value={Role.GATEKEEPER}>Gatekeeper</MenuItem>
                <MenuItem value={Role.PROJECTOR}>Main Projector</MenuItem>
                <MenuItem value={Role.PROJECTOR_FULL}>
                  Full Track Projector
                </MenuItem>
                <MenuItem value={Role.HISTOGRAM}>Histogram</MenuItem>
                <MenuItem value={Role.ADMIN}>System Admin</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {state.stationRequired ? (
            <Grid item>
              <TextField
                label="Station"
                type="number"
                variant="outlined"
                value={state.station}
                onChange={event => dispatch(changeStation(event.target.value))}
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h6">Type your provided role password</Typography>
      </Grid>
      <Grid item>
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          value={state.password}
          onChange={event => dispatch(changePassword(event.target.value))}
        />
      </Grid>
      <Grid item>
        <Button
          size="large"
          variant="contained"
          color="primary"
          disabled={!state.submitEnabled}
          onClick={() => dispatch(sendHandshake())}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  )
}
