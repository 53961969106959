import React from "react";
import { useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";

export default () => {
  const data = useSelector(state => state.admin.data);
  const rows = [];
  for (const line of data.users) {
    rows.push(
      <Typography key={line} variant="body1">
        {line}
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="h4">Connected Users</Typography>
      {rows}
    </>
  );
};
