import React from "react";
import { useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

export default () => {
  const data = useSelector(state => state.projector.data);
  const assignments = [];
  const queue = [];
  const lateQueue = [];

  for (const value of data.assignments) {
    assignments.push(
      <Typography
        variant="h3"
        style={{ margin: 8 }}
        dangerouslySetInnerHTML={{ __html: value }}
      />
    );
  }
  for (const value of data.queue) {
    queue.push(<Typography variant="h3">{value}</Typography>);
  }
  for (const value of data.lateQueue) {
    lateQueue.push(<Typography variant="h3">{value}</Typography>);
  }

  return (
    <Grid container spacing={0} direction="column" style={{ height: "100%" }}>
      <Grid item style={{ height: "95%" }}>
        <Grid container spacing={2} style={{ height: "100%" }}>
          <Grid item xs={9}>
            <Paper style={{ height: "100%", padding: 5 }}>
              <Typography variant="h2" style={{ fontWeight: "bold" }}>
                Subtask Stations
              </Typography>
              {assignments}
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Paper style={{ height: "100%", padding: 5 }}>
                  <Typography variant="h2" style={{ fontWeight: "bold" }}>
                    Queue
                  </Typography>
                  {queue}
                </Paper>
              </Grid>
              {lateQueue.length > 0 ? (
                <Grid item>
                  <Paper style={{ padding: 5 }}>
                    <Typography variant="h2" style={{ fontWeight: "bold" }}>
                      Late Queue
                    </Typography>
                    {lateQueue}
                  </Paper>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ height: "5%" }}>
        <Paper style={{ height: "100%", paddingLeft: 5, paddingRight: 5 }}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item>
              <Typography variant="h4">
                Teams Remaining: {data.remaining}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4">Late Teams: {data.late}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
