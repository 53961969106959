import Actions from "./types";
import { sendHandshake } from "./login";

export const socketDisconnect = () => ({ type: Actions.SOCKET_DISCONNECT });
export const socketConnect = () => ({ type: Actions.SOCKET_CONNECT });
export const socketCreate = socket => ({
  type: Actions.SOCKET_CREATE,
  socket: socket
});

export const tryReconnectLogin = () => (dispatch, getState) => {
  if (getState().login.accepted && !getState().disconnected) {
    dispatch(sendHandshake());
  }
};
