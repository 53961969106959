const actions = {
  // Socket
  SOCKET_CREATE: 0,
  SOCKET_DISCONNECT: 0,
  SOCKET_CONNECT: 0,

  // Misc
  UPDATE_SALT: 0,
  HACK_STATE: 0,

  // Login
  LOGIN_ACCEPTED: 0,
  OPEN_LOGIN_ERROR_SNACKBAR: 0,
  CLOSE_LOGIN_ERROR_SNACKBAR: 0,
  EXCLUSIVE_DISCONNECT: 0,
  LOGIN_UPDATE_ERROR_MESSAGE: 0,
  LOGIN_UPDATE_PASSWORD: 0,
  LOGIN_UPDATE_ROLE: 0,
  LOGIN_UPDATE_STATION: 0,
  LOGIN_SUBMIT_ENABLED: 0,
  LOGIN_STATION_REQUIRED: 0,

  // Gatekeeper
  GATEKEEPER_UPDATE_NUMBER: 0,
  GATEKEEPER_UPDATE_TEAM: 0,
  GATEKEEPER_UPDATE_PHASE: 0,
  GATEKEEPER_UPDATE_SUBMIT_ENABLED: 0,
  GATEKEEPER_UPDATE_RESPONSE: 0, // The actual response packet
  GATEKEEPER_UPDATE_CONFIRM: 0, // The actual confirmation packet
  GATEKEEPER_UPDATE_TASK_A: 0,
  GATEKEEPER_UPDATE_TASK_B: 0,
  GATEKEEPER_UPDATE_TASK_C: 0,
  GATEKEEPER_UPDATE_FULL_TRACK: 0,
  GATEKEEPER_UPDATE_CONFIRM_SUCCESS_SNACKBAR: 0,
  GATEKEEPER_UPDATE_CONFIRM_FAIL_SNACKBAR: 0,

  // Admin
  ADMIN_UPDATE_TAB: 0,
  ADMIN_UPDATE_UPLOAD_FAIL_SNACKBAR: 0,
  ADMIN_UPDATE_UPLOAD_SUCCESS_SNACKBAR: 0,
  ADMIN_UPDATE_UPLOAD_MESSAGE: 0,
  ADMIN_UPDATE_DATA: 0,
  ADMIN_UPDATE_REQUEST_NUMBER: 0,
  ADMIN_UPDATE_TEAM: 0,

  // Projector
  PROJECTOR_UPDATE_DATA: 0,
  PROJECTOR_FULL_UPDATE_DATA: 0,

  // Histogram
  HISTOGRAM_UPDATE_DATA: 0,

  // Station
  STATION_UPDATE_DATA: 0,
  STATION_UPDATE_DEMO_PHASE: 0,
  STATION_UPDATE_PASSED: 0,
  STATION_UPDATE_STARTING: 0,
  STATION_UPDATE_DISMISSAL_NOTE: 0,
  STATION_UPDATE_ALLOW_RETRY: 0,
  STATION_UPDATE_DISMISS_ENABLED: 0,
  STATION_UPDATE_SNACKBAR: 0,
  STATION_UPDATE_SNACKBAR_MESSAGE: 0,
  STATION_UPDATE_CONFIRM_TIME: 0,
  STATION_UPDATE_SUBMIT_ENABLED: 0,
}

for (const key in actions) {
  actions[key] = key
}

export default actions
