export const object = (type, def = {}, property = "value") => (
  state = def,
  action
) => {
  switch (action.type) {
    case type:
      return action[property];
    default:
      return state;
  }
};

export const string = (type, def = "", property = "value") => (
  state = def,
  action
) => {
  switch (action.type) {
    case type:
      return action[property];
    default:
      return state;
  }
};

export const boolean = (type, def = false, property = "value") => (
  state = def,
  action
) => {
  switch (action.type) {
    case type:
      return action[property];
    default:
      return state;
  }
};

export const integer = (type, def = 0, property = "value") => (
  state = def,
  action
) => {
  switch (action.type) {
    case type:
      return action[property];
    default:
      return state;
  }
};

export const list = (type, def = [], property = "value") => (
  state = def,
  action
) => {
  switch (action.type) {
    case type:
      return action[property];
    default:
      return state;
  }
};
