import { combineReducers } from "redux"
import Action from "../actions/types"
import { boolean, object, string } from "./generators"

export default combineReducers({
  team: object(Action.GATEKEEPER_UPDATE_TEAM),
  number: string(Action.GATEKEEPER_UPDATE_NUMBER),
  phase: string(Action.GATEKEEPER_UPDATE_PHASE, "request"),
  submitEnabled: string(Action.GATEKEEPER_UPDATE_SUBMIT_ENABLED, true),
  response: object(Action.GATEKEEPER_UPDATE_RESPONSE),
  confirm: object(Action.GATEKEEPER_UPDATE_CONFIRM),
  taskA: boolean(Action.GATEKEEPER_UPDATE_TASK_A),
  taskB: boolean(Action.GATEKEEPER_UPDATE_TASK_B),
  taskC: boolean(Action.GATEKEEPER_UPDATE_TASK_C),
  fullTrack: boolean(Action.GATEKEEPER_UPDATE_FULL_TRACK),
  confirmFailSnackbar: boolean(Action.GATEKEEPER_UPDATE_CONFIRM_FAIL_SNACKBAR),
  confirmSuccessSnackbar: boolean(
    Action.GATEKEEPER_UPDATE_CONFIRM_SUCCESS_SNACKBAR
  ),
})
