import Action from "./types";
import send from "../send";
import packets from "../packets";
import download from "downloadjs";
import blob from "b64-to-blob";

export const updateAdminTab = tab => ({
  type: Action.ADMIN_UPDATE_TAB,
  value: tab
});

export const updateUploadSuccessSnackbar = open => ({
  type: Action.ADMIN_UPDATE_UPLOAD_SUCCESS_SNACKBAR,
  value: open
});

export const updateUploadFailSnackbar = open => ({
  type: Action.ADMIN_UPDATE_UPLOAD_FAIL_SNACKBAR,
  value: open
});

export const updateRequestNumber = text => ({
  type: Action.ADMIN_UPDATE_REQUEST_NUMBER,
  value: text
});

export const sendRequestDownload = () => (dispatch, getState) => {
  send(packets.adminRequestDownload, {});
};

export const sendRequestUpload = files => (dispatch, getState) => {
  if (files.length === 0) {
    return;
  }
  const file = files[0];
  const reader = new FileReader();
  reader.onload = () => {
    const buffer = reader.result;
    const array = new Uint8Array(buffer);
    let binary = "";
    for (var i = 0; i < array.byteLength; i++) {
      binary += String.fromCharCode(array[i]);
    }
    send(packets.adminRequestUpload, { blob: window.btoa(binary) });
  };
  reader.readAsArrayBuffer(file);
};

export const sendRequesrFlush = () => dispatch => {
  send(packets.adminRequestFlush, {});
};

export const sendToggleLateQueue = () => dispatch => {
  send(packets.adminToggleLateQueue, {});
};

export const sendToggleTestData = () => dispatch => {
  send(packets.adminToggleTestData, {});
};

export const sendToggleQualify = (subtask, zone) => dispatch => {
  send(packets.adminToggleQualify, {
    task: subtask,
    zone: zone
  });
};

export const sendToggleStation = (full, station) => dispatch => {
  send(packets.adminToggleStation, {
    station: station,
    full: full
  });
};

export const sendRequestTeam = () => (dispatch, getState) => {
  if (isNaN(parseInt(getState().admin.requestNumber))) {
    return;
  }
  send(packets.adminRequestTeam, {
    number: getState().admin.requestNumber
  });
};

export const sendFreeTeam = () => (dispatch, getState) => {
  if (isNaN(parseInt(getState().admin.requestNumber))) {
    return;
  }
  send(packets.adminFreeTeam, {
    number: getState().admin.requestNumber
  });
};

export const handleUpdateAdmin = packet => ({
  type: Action.ADMIN_UPDATE_DATA,
  value: packet
});

export const handleAdminDownloadResponse = packet => dispatch => {
  download(
    blob(packet.blob),
    "results.xlsx",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );
};

export const handleAdminUploadResponse = packet => dispatch => {
  dispatch({ type: Action.ADMIN_UPDATE_UPLOAD_MESSAGE, value: packet.message });
  dispatch({
    type: packet.success
      ? Action.ADMIN_UPDATE_UPLOAD_SUCCESS_SNACKBAR
      : Action.ADMIN_UPDATE_UPLOAD_FAIL_SNACKBAR,
    value: true
  });
};

export const handleAdminTeamResponse = packet => ({
  type: Action.ADMIN_UPDATE_TEAM,
  value: packet.message
});

export const handleAdminFlushResponse = () => (dispatch, getState) => {};
