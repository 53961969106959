import {
  handleHandshakeResponse,
  handleNotifyDisconnect
} from "./actions/login";
import {
  handleGatekeeperResponse,
  handleGatekeeperComplete
} from "./actions/gatekeeper";
import {
  handleAdminDownloadResponse,
  handleAdminUploadResponse,
  handleAdminFlushResponse,
  handleUpdateAdmin,
  handleAdminTeamResponse
} from "./actions/admin";
import { handleUpdateProjector } from "./actions/projector";
import {
  handleUpdateStation,
  handleStationInfoSnackbar
} from "./actions/station";
import { handleUpdateHistogram } from "./actions/histogram";

export default {
  // ==============
  // == Outgoing ==
  // ==============

  // General
  handshake: "handshake",

  // Gatekeeper
  gatekeeperRequestTeam: "gatekeeper_request_team",
  gatekeeperConfirm: "gatekeeper_confirm",

  // Admin
  adminRequestUpload: "admin_request_upload",
  adminRequestDownload: "admin_request_download",
  adminRequestFlush: "admin_request_flush",
  adminToggleLateQueue: "admin_toggle_late_queue",
  adminToggleQualify: "admin_toggle_qualify",
  adminToggleStation: "admin_toggle_station",
  adminRequestTeam: "admin_request_team",
  adminFreeTeam: "admin_free_team",
  adminToggleTestData: "admin_toggle_test_data",

  // Station
  stationDismissTeam: "station_dismiss_team",
  stationBeginDemo: "station_begin_demo",
  stationSubmitData: "station_submit_data",
  stationAcknowledgeResults: "station_acknowledge_results",

  // ==============
  // == Incoming ==
  // ==============

  // General
  handshake_response: () => handleHandshakeResponse,
  notify_disconnect: () => handleNotifyDisconnect,

  // Gatekeeper
  gatekeeper_response: () => handleGatekeeperResponse,
  gatekeeper_complete: () => handleGatekeeperComplete,

  // Admin
  admin_download_response: () => handleAdminDownloadResponse,
  admin_upload_response: () => handleAdminUploadResponse,
  admin_flush_response: () => handleAdminFlushResponse,
  admin_team_response: () => handleAdminTeamResponse,
  update_admin: () => handleUpdateAdmin,

  // Projector
  update_projector: () => handleUpdateProjector,
  update_histogram: () => handleUpdateHistogram,

  // Station
  update_station: () => handleUpdateStation,
  station_info_snackbar: () => handleStationInfoSnackbar
};
