import React from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  gatekeeperBack,
  sendGatekeeperConfirm,
  updateTaskA,
  updateFullTrack,
  updateTaskB,
  updateTaskC,
} from "../../actions/gatekeeper"
import { useEnter } from "../../hooks"

import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import FormLabel from "@material-ui/core/FormLabel"
import FormControl from "@material-ui/core/FormControl"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"

export default () => {
  const dispatch = useDispatch()
  const state = useSelector(state => state.gatekeeper)

  const completeA = state.response.team.subtaskA.complete
  const completeB = state.response.team.subtaskB.complete
  const completeC = state.response.team.subtaskC.complete
  const completeFull = state.response.team.fullTrack.complete

  const checkboxes =
    completeA || completeB || completeC || completeFull ? (
      <Grid item>
        <FormControl component="fieldset">
          <FormLabel component="legend">Subtasks Required</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.taskA}
                  onChange={event =>
                    dispatch(updateTaskA(event.target.checked))
                  }
                />
              }
              label="Subtask A"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.taskB}
                  onChange={event =>
                    dispatch(updateTaskB(event.target.checked))
                  }
                />
              }
              label="Subtask B"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.taskC}
                  onChange={event =>
                    dispatch(updateTaskC(event.target.checked))
                  }
                />
              }
              label="Subtask C"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.fullTrack}
                  onChange={event =>
                    dispatch(updateFullTrack(event.target.checked))
                  }
                />
              }
              label="Full Track"
            />
          </FormGroup>
        </FormControl>
      </Grid>
    ) : (
      <></>
    )

  useEnter(() => {
    if (state.response.ok) {
      if (state.taskA || state.taskB || state.taskC || state.fullTrack) {
        dispatch(sendGatekeeperConfirm())
      }
    } else {
      dispatch(gatekeeperBack())
    }
  })

  let result = []
  result.push(
    <Grid item>
      <Typography variant="h2">Team {state.number}</Typography>
    </Grid>
  )
  if (state.response.error.length > 0) {
    const errors = []
    for (const message of state.response.error) {
      errors.push(
        <Grid item key={message}>
          <Typography variant="h4" style={{ color: "red" }}>
            {message}
          </Typography>
        </Grid>
      )
    }
    if (errors.length > 0) {
      result.push(
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            {errors}
          </Grid>
        </Grid>
      )
    }
  }
  if (state.response.ok) {
    result.push(
      <Grid item>
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item>
            <Typography variant="h5">
              Make sure one of the following people is standing in front of you
              before clicking confirm!
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">{state.response.names}</Typography>
          </Grid>
        </Grid>
      </Grid>
    )
    result.push(checkboxes)
    result.push(
      !(state.taskA || state.taskB || state.taskC) && state.fullTrack ? (
        <Typography variant="h6">
          Team will go directly to full track
        </Typography>
      ) : (
        <></>
      )
    )
    result.push(
      <Grid item>
        <Grid container alignItems="center" justify="center" spacing={2}>
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={() => dispatch(gatekeeperBack())}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="primary"
              disabled={
                !(state.taskA || state.taskB || state.taskC || state.fullTrack)
              }
              onClick={() => dispatch(sendGatekeeperConfirm())}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  } else {
    result.push(
      <Grid item>
        <Button
          size="large"
          variant="contained"
          color="secondary"
          onClick={() => dispatch(gatekeeperBack())}
        >
          Back
        </Button>
      </Grid>
    )
  }
  return (
    <Grid
      container
      direction="column"
      spacing={2}
      alignItems="center"
      justify="center"
      style={{ height: "100%" }}
    >
      {result}
    </Grid>
  )
}
