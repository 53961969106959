import React from "react";
import ReactDOM from "react-dom";
import store from "./store";

import { Provider } from "react-redux";
import Application from "./application";
import "./index.css";
import "typeface-roboto";
import { socketCreate } from "./actions/net";
import socket from "./socket";

store.dispatch(socketCreate(socket));
ReactDOM.render(
  <Provider store={store}>
    <Application />
  </Provider>,
  document.getElementById("root")
);
