import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Database from "./database";
import Status from "./status";
import Gatekeeper from "../gatekeeper";
import Users from "./users";
import Qualify from "./qualify";
import Demo from "./demo";
import Team from "./team";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { updateAdminTab } from "../../actions/admin";
import { UploadFailSnackbar, UploadSuccessSnackbar } from "./snackbars";
import Box from "@material-ui/core/Box";

const Panel = props => {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box style={{ height: "100%" }} p={3}>
        {children}
      </Box>
    </Typography>
  );
};

export default () => {
  const dispatch = useDispatch();
  const tab = useSelector(state => state.admin.tab);

  return (
    <>
      <div style={{ height: "100%", display: "flex", flexFlow: "column" }}>
        <AppBar position="static" style={{ flex: "0 1 auto" }}>
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography variant="h6" color="inherit" noWrap>
                  Admin Control Panel
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <Tabs
            value={tab}
            onChange={(event, index) => dispatch(updateAdminTab(index))}
          >
            <Tab label="System Monitor" />
            <Tab label="Manage Demo" />
            <Tab label="Manage Team" />
            <Tab label="Qualifying Criteria" />
            <Tab label="Database" />
            <Tab label="Gatekeeper" />
            <Tab label="User Monitor" />
          </Tabs>
        </AppBar>
        <div style={{ flex: "1 1 auto" }}>
          <Panel value={tab} index={0}>
            <Status />
          </Panel>
          <Panel value={tab} index={1}>
            <Demo />
          </Panel>
          <Panel value={tab} index={2}>
            <Team />
          </Panel>
          <Panel value={tab} index={3}>
            <Qualify />
          </Panel>
          <Panel value={tab} index={4}>
            <Database />
          </Panel>
          <Panel value={tab} index={5} style={{ height: "100%" }}>
            <Gatekeeper style={{ height: "100%" }} />
          </Panel>
          <Panel value={tab} index={6}>
            <Users />
          </Panel>
        </div>
      </div>
      <UploadFailSnackbar />
      <UploadSuccessSnackbar />
    </>
  );
};
