import React from "react"
import { useSelector } from "react-redux"
import Role from "./roles"
import Gatekeeper from "./components/gatekeeper"
import Admin from "./components/admin"
import Projector from "./components/projector/main"
import ProjectorFull from "./components/projector/full"
import Login from "./components/login"
import Bar from "./components/bar"
import Snackbars from "./components/snackbars"
import Connecting from "./components/connecting"
import Disconnected from "./components/disconnected"
import Station from "./components/station"

import Grid from "@material-ui/core/Grid"
import CssBaseline from "@material-ui/core/CssBaseline"

export default () => {
  const connected = useSelector(state => state.connected)
  const accepted = useSelector(state => state.login.accepted)
  const disconnected = useSelector(state => state.disconnected)
  const role = useSelector(state => state.login.role)

  const Histogram = React.lazy(() => import("./components/histogram"))

  let content = <></>
  if (!connected) {
    content = <Connecting />
  } else if (disconnected) {
    content = <Disconnected />
  } else if (!accepted) {
    content = <Login />
  } else {
    switch (role) {
      case Role.ADMIN:
        return <Admin />
      case Role.GATEKEEPER:
        content = <Gatekeeper />
        break
      case Role.PROJECTOR:
        content = <Projector />
        break
      case Role.PROJECTOR_FULL:
        content = <ProjectorFull />
        break
      case Role.STATION_A:
      case Role.STATION_B:
      case Role.STATION_C:
      case Role.STATION_FULL:
        content = <Station />
        break
      case Role.HISTOGRAM:
        content = (
          <React.Suspense fallback="Loading...">
            <Histogram />
          </React.Suspense>
        )
        break
      default:
        content = <></>
        break
    }
  }

  return (
    <div style={{ height: "100%" }}>
      <CssBaseline />
      <div style={{ height: "100%", display: "flex", flexFlow: "column" }}>
        <Bar />
        <Grid
          container
          justify="flex-start"
          direction="column"
          spacing={0}
          style={{ flex: "1 1 auto" }}
        >
          <Grid item style={{ height: "100%" }}>
            <div style={{ height: "100%", padding: 20 }}>{content}</div>
          </Grid>
        </Grid>
      </div>
      <Snackbars />
    </div>
  )
}
