import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updateUploadFailSnackbar,
  updateUploadSuccessSnackbar
} from "../../actions/admin";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import ErrorIcon from "@material-ui/icons/Error";
import CheckIcon from "@material-ui/icons/Check";
import { red, green } from "@material-ui/core/colors";

export const UploadFailSnackbar = () => {
  const open = useSelector(state => state.admin.uploadFailSnackbar);
  const message = useSelector(state => state.admin.uploadMessage);
  const dispatch = useDispatch();
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      onClose={() => dispatch(updateUploadFailSnackbar(false))}
      open={open}
      autoHideDuration={5000}
    >
      <SnackbarContent
        style={{ backgroundColor: red[600] }}
        message={
          <span style={{ alignItems: "center", display: "flex" }}>
            <ErrorIcon style={{ marginRight: 10 }} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => dispatch(updateUploadFailSnackbar(false))}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
};

export const UploadSuccessSnackbar = () => {
  const open = useSelector(state => state.admin.uploadSuccessSnackbar);
  const message = useSelector(state => state.admin.uploadMessage);
  const dispatch = useDispatch();
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      onClose={() => dispatch(updateUploadSuccessSnackbar(false))}
      open={open}
      autoHideDuration={5000}
    >
      <SnackbarContent
        style={{ backgroundColor: green[600] }}
        message={
          <span style={{ alignItems: "center", display: "flex" }}>
            <CheckIcon style={{ marginRight: 10 }} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => dispatch(updateUploadSuccessSnackbar(false))}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
};
