import { combineReducers } from "redux";
import Action from "../actions/types";
import { object, list, boolean, string, integer } from "./generators";

export default combineReducers({
  data: object(Action.STATION_UPDATE_DATA, {
    phase: "idle",
    time: 0,
    bypass: false,
    zones: 0,
    zoneNames: [],
    team: 0,
    message: "",
    results: {
      qualified: false
    }
  }),
  demoPhase: string(Action.STATION_UPDATE_DEMO_PHASE),
  passed: list(Action.STATION_UPDATE_PASSED),
  starting: list(Action.STATION_UPDATE_STARTING),
  dismissalNote: string(Action.STATION_UPDATE_DISMISSAL_NOTE),
  allowRetry: boolean(Action.STATION_UPDATE_ALLOW_RETRY, true),
  dismissEnabled: boolean(Action.STATION_UPDATE_DISMISS_ENABLED),
  rolling: boolean(Action.STATION_UPDATE_ROLLING),
  controlled: boolean(Action.STATION_UPDATE_CONTROLLED),
  snackbarMessage: string(Action.STATION_UPDATE_SNACKBAR_MESSAGE),
  snackbar: boolean(Action.STATION_UPDATE_SNACKBAR),
  confirmTime: integer(Action.STATION_UPDATE_CONFIRM_TIME),
  submitEnabled: boolean(Action.STATION_UPDATE_SUBMIT_ENABLED, true)
});
