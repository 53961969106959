import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { changeNumber, sendGatekeeperRequest } from "../../actions/gatekeeper";
import { useEnter } from "../../hooks";

export default () => {
  const dispatch = useDispatch();
  const enabled = useSelector(state => state.gatekeeper.submitEnabled);
  const number = useSelector(state => state.gatekeeper.number);

  useEnter(() => dispatch(sendGatekeeperRequest()));

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      alignItems="center"
      justify="center"
      style={{ height: "100%" }}
    >
      <Grid item>
        <Typography variant="h2">Gatekeeper</Typography>
      </Grid>
      <Grid item>
        <TextField
          id="team-number"
          label="Team Number"
          value={number}
          onChange={event => dispatch(changeNumber(event.target.value))}
          type="number"
          margin="normal"
          variant="outlined"
        />
      </Grid>
      <Grid item>
        <Button
          size="large"
          variant="contained"
          color="primary"
          disabled={!enabled}
          onClick={() => dispatch(sendGatekeeperRequest())}
        >
          CHECK IN
        </Button>
      </Grid>
    </Grid>
  );
};
