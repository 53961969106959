import React from "react";
import { useDispatch } from "react-redux";
import {
  sendRequestDownload,
  sendRequestUpload,
  sendRequesrFlush
} from "../../actions/admin";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

export default () => {
  const dispatch = useDispatch();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => dispatch(sendRequestDownload())}
            >
              Download Database
            </Button>
          </Grid>
          <Grid item>
            <input
              accept="*"
              style={{ display: "none" }}
              id="upload-file"
              type="file"
              onChange={event =>
                dispatch(sendRequestUpload(event.target.files))
              }
            />
            <label htmlFor="upload-file">
              <Button variant="contained" color="primary" component="span">
                Upload Database
              </Button>
            </label>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(sendRequesrFlush())}
            >
              Flush Database
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
