import React from "react";
import { useSelector } from "react-redux";

import Entry from "./entry";
import Dismiss from "./dismiss";
import Confirm from "./confirm";

export default () => {
  const phase = useSelector(state => state.station.demoPhase);
  switch (phase) {
    case "dismiss":
      return <Dismiss />;
    case "confirm":
      return <Confirm />;
    default:
      return <Entry />;
  }
};
