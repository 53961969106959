export default {
  STATION_A: "station_a",
  STATION_B: "station_b",
  STATION_C: "station_c",
  STATION_FULL: "station_full",
  GATEKEEPER: "gatekeeper",
  PROJECTOR: "projector",
  PROJECTOR_FULL: "projector_full",
  HISTOGRAM: "histogram",
  ADMIN: "admin",
}
