import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { TEAM_NUMBER_SIZE } from "../../config"
import Role from "../.././roles"
import {
  updateDemoPhase,
  updatePassed,
  submitEntry,
  updateStarting,
} from "../../actions/station"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Button from "@material-ui/core/Button"
import Switch from "@material-ui/core/Switch"
import { useEnter } from "../../hooks"

export default () => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.station.data)
  const passed = useSelector(state => state.station.passed)
  const starting = useSelector(state => state.station.starting)
  const role = useSelector(state => state.login.role)
  const zones = []

  useEnter(() => dispatch(submitEntry()))

  for (let index = 1; index <= data.zones; index++) {
    zones.push(
      <Grid item key={index}>
        <Grid
          container
          direction="column"
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={passed.includes(index)}
                  onChange={event =>
                    dispatch(updatePassed(index, event.target.checked))
                  }
                  color="primary"
                />
              }
              labelPlacement="top"
              label={data.zoneNames[index - 1]}
            />
          </Grid>
          {data.zoneNames[index - 1] === "Avoided Objects" ||
          data.zoneNames[index - 1] === "In Bounds" ? (
            <></>
          ) : (
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={starting.includes(index)}
                    onChange={event =>
                      dispatch(updateStarting(index, event.target.checked))
                    }
                    color="primary"
                  />
                }
                labelPlacement="top"
                label={"Retry"}
                disabled={!passed.includes(index)}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justify="center"
      direction="column"
      style={{ height: "100%", width: "100%" }}
    >
      <Grid item>
        <Typography variant="h4">Team</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h3" style={{ fontSize: TEAM_NUMBER_SIZE }}>
          {data.team}
        </Typography>
      </Grid>
      {role !== Role.STATION_A ? (
        <></>
      ) : (
        <Grid item>
          <Typography variant="h3">Target Location: {data.location}</Typography>
        </Grid>
      )}
      {role !== Role.STATION_C ? (
        <></>
      ) : (
        <Grid item>
          <Typography variant="h3">Target Barcode: {data.barcode}</Typography>
        </Grid>
      )}
      <Grid item>
        <Typography variant="h2">{data.message}</Typography>
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item>
            <Grid container alignItems="center" justify="center">
              {zones}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2} alignItems="center" justify="center">
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(updateDemoPhase("dismiss"))}
            >
              Dismiss Team
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => dispatch(submitEntry())}
            >
              Submit Data
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
