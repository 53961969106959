import { useEffect } from "react";

export const useEnter = action => {
  useEffect(() => {
    const handler = event => {
      if (event.keyCode === 13) {
        action();
      }
    };
    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [action]);
};
