import Actions from "./types"
import send from "../send"
import packets from "../packets"
import Role from "../roles"

export const closeLoginErrorSnackbar = () => ({
  type: Actions.CLOSE_LOGIN_ERROR_SNACKBAR,
})
export const openLoginErrorSnackbar = () => ({
  type: Actions.OPEN_LOGIN_ERROR_SNACKBAR,
})

export const updateLoginStationRequired = () => (dispatch, getState) => {
  const required = [
    Role.STATION_A,
    Role.STATION_B,
    Role.STATION_C,
    Role.STATION_FULL,
  ]
  dispatch({
    type: Actions.LOGIN_STATION_REQUIRED,
    required: required.some(r => r === getState().login.role),
  })
}

const updateSubmitEnabled = () => (dispatch, getState) => {
  dispatch(updateLoginStationRequired())
  const state = getState().login
  const disabled =
    state.role === "" ||
    state.password === undefined ||
    state.password === "" ||
    (state.stationRequired &&
      (isNaN(parseInt(state.station)) || parseInt(state.station) <= 0))
  dispatch({ type: Actions.LOGIN_SUBMIT_ENABLED, value: !disabled })
}

export const changePassword = password => dispatch => {
  dispatch({ type: Actions.LOGIN_UPDATE_PASSWORD, password: password })
  dispatch(updateSubmitEnabled())
}

export const changeRole = role => dispatch => {
  dispatch({ type: Actions.LOGIN_UPDATE_ROLE, role: role })
  dispatch(updateSubmitEnabled())
}

export const changeStation = station => dispatch => {
  dispatch({ type: Actions.LOGIN_UPDATE_STATION, station: station })
  dispatch(updateSubmitEnabled())
}

export const sendHandshake = () => (dispatch, getState) => {
  const state = getState().login
  if (!state.submitEnabled) {
    return
  }
  send(packets.handshake, {
    role: state.role,
    password: state.password,
    station: state.station ? state.station : "0",
  })
}

export const handleHandshakeResponse = packet => (dispatch, getState) => {
  if (packet.accepted) {
    dispatch({ type: Actions.LOGIN_ACCEPTED })
    dispatch({ type: Actions.UPDATE_SALT, salt: packet.salt })
  } else {
    dispatch({
      type: Actions.LOGIN_UPDATE_ERROR_MESSAGE,
      error: packet.error,
    })
    dispatch(openLoginErrorSnackbar())
  }
}

export const handleNotifyDisconnect = packet => (dispatch, getState) => {
  dispatch({ type: Actions.EXCLUSIVE_DISCONNECT })
}
