import React from "react";
import { useSelector } from "react-redux";

import Request from "./request";
import Confirm from "./confirm";

export default () => {
  const phase = useSelector(state => state.gatekeeper.phase);
  switch (phase) {
    case "confirm":
      return <Confirm />;
    default:
      return <Request />;
  }
};
