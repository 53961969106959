import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { sendAcknowledgeResults } from "../../actions/station"
import Role from "../../roles"
import { useEnter } from "../../hooks"

import "./fireworks.css"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import Chip from "@material-ui/core/Chip"
import DoneIcon from "@material-ui/icons/Done"
import CloseIcon from "@material-ui/icons/Close"

export default () => {
  const dispatch = useDispatch()
  const role = useSelector(state => state.login.role)
  const data = useSelector(state => state.station.data)
  const results = useSelector(state => state.station.data.results)
  const rows = []

  useEnter(() => dispatch(sendAcknowledgeResults()))

  if (role !== Role.STATION_FULL && results.qualified) {
    return (
      <>
        <div class="pyro">
          <div class="before"></div>
          <div class="after"></div>
        </div>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justify="center"
          direction="column"
          style={{ height: "100%", width: "100%" }}
        >
          <Grid item>
            <Typography variant="h2">Team {data.team}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h1">Qualified for full track</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              This team should immediately proceed to the Full Track demo area.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => dispatch(sendAcknowledgeResults())}
            >
              Acknowledge Results
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }

  let fullPassed = 0
  for (const stationName of ["a", "b", "c", "full"]) {
    const station = results[stationName]
    const chips = []
    if (stationName === "full" && station.result.complete) {
      fullPassed++
    }
    for (let index = 0; index < station.zoneNames.length; index++) {
      const passed = station.result.passed.includes(index + 1)
      if (stationName === "full" && passed) {
        fullPassed++
      }
      chips.push(
        <Grid item key={index}>
          <Chip
            label={station.zoneNames[index]}
            onClick={() => {}}
            onDelete={() => {}}
            color={passed ? "primary" : ""}
            deleteIcon={passed ? <DoneIcon /> : <CloseIcon />}
          />
        </Grid>
      )
    }
    rows.push(
      <Grid container spacing={1}>
        {chips}
      </Grid>
    )
  }

  if (!results.qualified && fullPassed === 0) {
    rows[3] = (
      <Typography variant="h6" style={{ color: "red" }}>
        Did not qualify
      </Typography>
    )
  }

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justify="center"
      direction="column"
      style={{ height: "100%", width: "100%" }}
    >
      <Grid item>
        <Typography variant="h2">Team {data.team} Demo Results</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6">
          One team member should take a photo of this page as a record of the
          team's demo score.
        </Typography>
      </Grid>
      <Grid item>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Subtask</TableCell>
                <TableCell>Zones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Subtask 1<br />
                  (Navigation A→B)
                </TableCell>
                <TableCell>{rows[0]}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Subtask 2<br />
                  (Navigation B→A)
                </TableCell>
                <TableCell>{rows[1]}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Subtask 3<br />
                  (Scan/Lift)
                </TableCell>
                <TableCell>{rows[2]}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Full Track
                </TableCell>
                <TableCell>{rows[3]}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => dispatch(sendAcknowledgeResults())}
        >
          Acknowledge Results
        </Button>
      </Grid>
    </Grid>
  )
}
