import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeLoginErrorSnackbar } from "../../actions/login";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import ErrorIcon from "@material-ui/icons/Error";
import { red } from "@material-ui/core/colors";

export default () => {
  const open = useSelector(state => state.login.loginErrorSnackbarOpen);
  const message = useSelector(state => state.login.error);
  const dispatch = useDispatch();
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      onClose={() => dispatch(closeLoginErrorSnackbar())}
      open={open}
      autoHideDuration={3000}
    >
      <SnackbarContent
        style={{ backgroundColor: red[600] }}
        message={
          <span
            id="wrong-password-text"
            style={{ alignItems: "center", display: "flex" }}
          >
            <ErrorIcon style={{ marginRight: 10 }} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => dispatch(closeLoginErrorSnackbar())}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
};
