import React from "react";
import WrongPasswordSnackbar from "./login/snackbar";
import {
  GatekeeperConfirmFailSnackbar,
  GatekeeperConfirmSuccessSnackbar
} from "./gatekeeper/snackbars";
import { StationInfoSnackbar } from "./station/snackbars";

export default () => (
  <>
    <WrongPasswordSnackbar />
    <GatekeeperConfirmFailSnackbar />
    <GatekeeperConfirmSuccessSnackbar />
    <StationInfoSnackbar />
  </>
);
