import { combineReducers } from "redux";
import Action from "../actions/types";
import { object } from "./generators";

export default combineReducers({
  data: object(Action.PROJECTOR_UPDATE_DATA, {
    assignments: [],
    fullAssignments: [],
    queue: [],
    lateQueue: [],
    fullQueue: [],
    late: 0,
    remaining: 0
  })
});
