import React from "react";
import { useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

export default () => {
  const data = useSelector(state => state.projector.data);
  const assignments = [];
  const queue = [];

  for (const value of data.fullAssignments) {
    assignments.push(
      <Typography
        variant="h3"
        style={{ margin: 8 }}
        dangerouslySetInnerHTML={{ __html: value }}
      />
    );
  }
  for (const value of data.fullQueue) {
    queue.push(<Typography variant="h3">{value}</Typography>);
  }

  return (
    <Grid container spacing={2} style={{ height: "100%" }}>
      <Grid item xs={9}>
        <Paper style={{ height: "100%", padding: 5 }}>
          <Typography variant="h2" style={{ fontWeight: "bold" }}>
            Full Track Stations
          </Typography>
          {assignments}
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Paper style={{ height: "100%", padding: 5 }}>
              <Typography variant="h2" style={{ fontWeight: "bold" }}>
                Queue
              </Typography>
              {queue}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
