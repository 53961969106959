import { combineReducers } from "redux";
import { fromJS, mergeDeep, set } from "immutable";
import login from "./login";
import gatekeeper from "./gatekeeper";
import admin from "./admin";
import projector from "./projector";
import station from "./station";
import histogram from "./histogram";
import Action from "../actions/types";
import { string, object } from "./generators";

const connected = (state = false, action) => {
  switch (action.type) {
    case Action.SOCKET_CONNECT:
      return true;
    case Action.SOCKET_DISCONNECT:
      return false;
    default:
      return state;
  }
};

const disconnected = (state = false, action) => {
  switch (action.type) {
    case Action.EXCLUSIVE_DISCONNECT:
      return true;
    default:
      return state;
  }
};

export default (state, action) => {
  if (action.type === Action.HACK_STATE) {
    const mergeValue = action.state || action.value;
    const mergeHack = action.path
      ? set({}, action.path, mergeValue)
      : mergeValue;
    return mergeDeep(state, fromJS(mergeHack));
  }
  return combineReducers({
    login: login,
    gatekeeper: gatekeeper,
    admin: admin,
    connected: connected,
    disconnected: disconnected,
    projector: projector,
    histogram: histogram,
    station: station,
    salt: string(Action.UPDATE_SALT, "", "salt"),
    socket: object(Action.SOCKET_CREATE, {}, "socket")
  })(state, action);
};
