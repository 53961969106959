import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateInfoSnackbar } from "../../actions/station";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import InfoIcon from "@material-ui/icons/Info";
import { blue } from "@material-ui/core/colors";

export const StationInfoSnackbar = () => {
  const message = useSelector(state => state.station.snackbarMessage);
  const open = useSelector(state => state.station.snackbar);
  const dispatch = useDispatch();
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      onClose={() => dispatch(updateInfoSnackbar(false))}
      open={open}
      autoHideDuration={5000}
    >
      <SnackbarContent
        style={{ backgroundColor: blue[600] }}
        message={
          <span style={{ alignItems: "center", display: "flex" }}>
            <InfoIcon style={{ marginRight: 10 }} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => dispatch(updateInfoSnackbar(false))}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
};
