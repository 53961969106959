import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  updateRequestNumber,
  sendRequestTeam,
  sendFreeTeam,
} from "../../actions/admin"

import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"

export default () => {
  const dispatch = useDispatch()
  const message = useSelector(state => state.admin.team)
  const number = useSelector(state => state.admin.requestNumber)
  let display = ""

  for (const line of message) {
    display = display + line + "\n"
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h4">Request Team</Typography>
      </Grid>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <TextField
            id="team-number"
            label="Team Number"
            value={number}
            onChange={event =>
              dispatch(updateRequestNumber(event.target.value))
            }
            type="number"
            margin="normal"
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={isNaN(parseInt(number))}
            onClick={() => dispatch(sendRequestTeam())}
          >
            Request
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={isNaN(parseInt(number))}
            onClick={() => dispatch(sendFreeTeam())}
          >
            Free Team
          </Button>
        </Grid>
      </Grid>
      {message.length <= 0 ? (
        <></>
      ) : (
        <>
          <Grid item>
            <Typography variant="h4">Response</Typography>
          </Grid>
          <Grid item>
            <TextField
              multiline={true}
              fullWidth={true}
              id="team-display"
              value={display}
              rowsMax={25}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}
