import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  sendToggleLateQueue,
  sendToggleStation,
  sendToggleTestData
} from "../../actions/admin";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";

export default () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.admin.data);

  const subtasks = [];
  const full = [];
  for (let index = 1; index <= data.stations; index++) {
    const enabled = !data.disabledStations.includes(index);
    subtasks.push(
      <Grid item key={index}>
        <Chip
          label={"Station " + index}
          onClick={() => dispatch(sendToggleStation(false, index))}
          onDelete={() => dispatch(sendToggleStation(false, index))}
          color={enabled ? "primary" : ""}
          deleteIcon={enabled ? <DoneIcon /> : <CloseIcon />}
        />
      </Grid>
    );
  }
  for (let index = 1; index <= data.fullStations; index++) {
    const enabled = !data.disabledFullStations.includes(index);
    full.push(
      <Grid item key={index}>
        <Chip
          label={"Station " + index}
          onClick={() => dispatch(sendToggleStation(true, index))}
          onDelete={() => dispatch(sendToggleStation(true, index))}
          color={enabled ? "primary" : ""}
          deleteIcon={enabled ? <DoneIcon /> : <CloseIcon />}
        />
      </Grid>
    );
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h4">Late Queue</Typography>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => dispatch(sendToggleLateQueue())}
        >
          {data.lateQueue ? "ENABLED" : "DISABLED"}
        </Button>
      </Grid>
      <Grid item>
        <Typography variant="h4">Enabled Subtask Stations</Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          {subtasks}
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h4">Enabled Full Track Stations</Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          {full}
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h4">Test Data</Typography>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => dispatch(sendToggleTestData())}
        >
          {data.testData ? "ENABLED" : "DISABLED"}
        </Button>
      </Grid>
    </Grid>
  );
};
