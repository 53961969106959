import ReconnectingWebSocket from "reconnecting-websocket";
import {
  socketDisconnect,
  socketConnect,
  tryReconnectLogin
} from "./actions/net";
import store from "./store";
import packets from "./packets";
import { SOCKET_URL } from "./config";

const socket = new ReconnectingWebSocket(SOCKET_URL + "/socket", [], {
  minReconnectionDelay: Math.random() * 10,
  maxReconnectionDelay: 1000 + Math.random() * 1000,
  maxRetries: 1200
});
socket.onmessage = data => handle(data);
socket.onclose = () => disconnect();
socket.onerror = () => disconnect();
socket.onopen = () => connect();

const connect = () => {
  store.dispatch(socketConnect());
  setTimeout(() => store.dispatch(tryReconnectLogin()), 500);
};

const disconnect = () => store.dispatch(socketDisconnect());

const handle = data => {
  const packet = JSON.parse(data.data);
  if (packet.type in packets) {
    store.dispatch(packets[packet.type]()(packet));
  }
};

export default socket;
