import React from "react"
import { useSelector } from "react-redux"
import Role from "../roles"

import Grid from "@material-ui/core/Grid"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"

export default () => {
  const role = useSelector(state => state.login.role)
  const station = useSelector(state => state.login.station)
  const accepted = useSelector(state => state.login.accepted)
  let extension = ""
  if (accepted) {
    switch (role) {
      case Role.STATION_A:
        extension = "Station " + station + " Subtask A"
        break
      case Role.STATION_B:
        extension = "Station " + station + " Subtask B"
        break
      case Role.STATION_C:
        extension = "Station " + station + " Subtask C"
        break
      case Role.STATION_FULL:
        extension = "Full Track Station " + station
        break
      default:
        break
    }
  }

  return (
    <AppBar position="static" style={{ flex: "0 1 auto" }}>
      <Toolbar>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="h6" color="inherit" noWrap>
              ENED 1120 Project 4 Final Demo
              {extension.length > 0 ? " (" + extension + ")" : ""}
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
