import React from "react";
import { useSelector } from "react-redux";

import Waiting from "./waiting";
import Idle from "./idle";
import Demo from "./demo";
import Results from "./results";
import Complete from "./complete";

export default () => {
  const phase = useSelector(state => state.station.data.phase);
  switch (phase) {
    case "waiting":
      return <Waiting />;
    case "demo":
      return <Demo />;
    case "results":
      return <Results />;
    case "complete":
      return <Complete />;
    default:
      return <Idle />;
  }
};
