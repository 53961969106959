import Action from "./types"
import send from "../send"
import packets from "../packets"

const updateDismissEnabled = () => (dispatch, getState) => {
  const state = getState().station
  dispatch({
    type: Action.STATION_UPDATE_DISMISS_ENABLED,
    value: state.dismissalNote ? true : false,
  })
}

const tickConfirmCountdown = () => (dispatch, getState) => {
  setTimeout(() => {
    const result = getState().station.confirmTime - 1
    dispatch({ type: Action.STATION_UPDATE_CONFIRM_TIME, value: result })
    if (result > 0) {
      dispatch(tickConfirmCountdown())
    }
  }, 1000)
}

export const updateDemoPhase = phase => ({
  type: Action.STATION_UPDATE_DEMO_PHASE,
  value: phase,
})

export const updatePassed = (station, passed) => (dispatch, getState) => {
  let result = [...getState().station.passed]
  if (passed) {
    if (!result.includes(station)) {
      result.push(station)
    }
  } else {
    result = result.filter(s => s !== station)
  }
  dispatch({ type: Action.STATION_UPDATE_PASSED, value: result })
}

export const updateStarting = (station, passed) => (dispatch, getState) => {
  let result = [...getState().station.starting]
  if (passed) {
    if (!result.includes(station)) {
      result.push(station)
    }
  } else {
    result = result.filter(s => s !== station)
  }
  dispatch({ type: Action.STATION_UPDATE_STARTING, value: result })
}

export const updateAllowRetry = retry => ({
  type: Action.STATION_UPDATE_ALLOW_RETRY,
  value: retry,
})

export const updateDismissalNote = note => dispatch => {
  dispatch({
    type: Action.STATION_UPDATE_DISMISSAL_NOTE,
    value: note,
  })
  dispatch(updateDismissEnabled())
}

export const updateInfoSnackbar = open => ({
  type: Action.STATION_UPDATE_SNACKBAR,
  value: open,
})

export const didNotArrive = () => (dispatch, getState) => {
  // TODO include station and subtask in this message
  dispatch(sendDismissTeam("Team did not arrive at station", true))
}

export const submitEntry = () => dispatch => {
  dispatch({ type: Action.STATION_UPDATE_SUBMIT_ENABLED, value: true })
  dispatch({ type: Action.STATION_UPDATE_CONFIRM_TIME, value: 5 })
  dispatch(tickConfirmCountdown())
  dispatch(updateDemoPhase("confirm"))
}

export const sendBeginDemo = () => (dispatch, getState) => {
  dispatch(updateDemoPhase("entry"))
  dispatch(updateAllowRetry(true))
  dispatch(updateDismissalNote(""))
  dispatch(updateDismissEnabled())
  dispatch({ type: Action.STATION_UPDATE_CONFIRM_TIME, value: 0 })
  dispatch({ type: Action.STATION_UPDATE_SUBMIT_ENABLED, value: true })
  dispatch({ type: Action.STATION_UPDATE_PASSED, value: [] })
  send(packets.stationBeginDemo, {})
}

export const sendAcknowledgeResults = () => (dispatch, getState) => {
  send(packets.stationAcknowledgeResults, {})
}

export const sendDismissTeam = (message, retry) => dispatch => {
  send(packets.stationDismissTeam, {
    message: message,
    retry: retry,
  })
}

export const sendSubmitData = () => (dispatch, getState) => {
  const state = getState().station
  if (state.confirmTime > 0 || !state.submitEnabled) {
    return
  }
  dispatch({ type: Action.STATION_UPDATE_SUBMIT_ENABLED, value: false })
  setTimeout(2500, () =>
    dispatch({ type: Action.STATION_UPDATE_SUBMIT_ENABLED, value: true })
  )
  send(packets.stationSubmitData, {
    passed: state.passed,
    starting: state.starting,
    rolling: state.rolling,
    controlled: state.controlled,
  })
}

export const handleUpdateStation = packet => dispatch => {
  dispatch({ type: Action.STATION_UPDATE_DATA, value: packet })
}

export const handleStationInfoSnackbar = packet => dispatch => {
  dispatch({
    type: Action.STATION_UPDATE_SNACKBAR_MESSAGE,
    value: packet.message,
  })
  dispatch(updateInfoSnackbar(true))
}
