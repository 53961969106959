import { combineReducers } from "redux";
import Action from "../actions/types";
import { boolean, string } from "./generators";

const loginErrorSnackbarOpen = (state = false, action) => {
  switch (action.type) {
    case Action.OPEN_LOGIN_ERROR_SNACKBAR:
      return true;
    case Action.CLOSE_LOGIN_ERROR_SNACKBAR:
      return false;
    default:
      return state;
  }
};

const accepted = (state = false, action) => {
  switch (action.type) {
    case Action.LOGIN_ACCEPTED:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  loginErrorSnackbarOpen: loginErrorSnackbarOpen,
  accepted: accepted,
  error: string(Action.LOGIN_UPDATE_ERROR_MESSAGE, "", "error"),
  password: string(Action.LOGIN_UPDATE_PASSWORD, "", "password"),
  role: string(Action.LOGIN_UPDATE_ROLE, "", "role"),
  station: string(Action.LOGIN_UPDATE_STATION, "", "station"),
  stationRequired: boolean(Action.LOGIN_STATION_REQUIRED, false, "required"),
  submitEnabled: boolean(Action.LOGIN_SUBMIT_ENABLED)
});
