import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { sendToggleQualify } from "../../actions/admin"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Chip from "@material-ui/core/Chip"
import DoneIcon from "@material-ui/icons/Done"
import CloseIcon from "@material-ui/icons/Close"

export default () => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.admin.data)

  const rows = []
  for (const task of ["A", "B", "C"]) {
    const chips = []
    for (let index = 0; index < data["zones" + task].length; index++) {
      const enabled = data["qualify" + task].includes(index + 1)
      chips.push(
        <Grid item key={index}>
          <Chip
            label={data["zones" + task][index]}
            onClick={() => dispatch(sendToggleQualify(task, index + 1))}
            onDelete={() => dispatch(sendToggleQualify(task, index + 1))}
            color={enabled ? "primary" : ""}
            deleteIcon={enabled ? <DoneIcon /> : <CloseIcon />}
          />
        </Grid>
      )
    }
    rows.push(
      <Grid item key={task}>
        <Grid container spacing={1}>
          <Grid item>
            <Grid item>
              <Typography variant="h6">Subtask {task}</Typography>
            </Grid>
          </Grid>
          {chips}
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h4">Qualifying Criteria</Typography>
      </Grid>
      {rows}
    </Grid>
  )
}
