import React from "react";
import { useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export default () => {
  const message = useSelector(state => state.station.data.message);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justify="center"
      direction="column"
      style={{ height: "100%" }}
    >
      <Grid item>
        <Typography variant="h1">Waiting for team...</Typography>
      </Grid>
      {message ? (
        <Grid item>
          <Typography variant="h4">{message}</Typography>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};
