import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TEAM_NUMBER_SIZE } from "../../config";
import { didNotArrive, sendBeginDemo } from "../../actions/station";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useEnter } from "../../hooks";

export default () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.station.data);

  useEnter(() => dispatch(sendBeginDemo()));

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justify="center"
      direction="column"
      style={{ height: "100%", width: "100%" }}
    >
      <Grid item>
        <Typography variant="h4">Team</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h3" style={{ fontSize: TEAM_NUMBER_SIZE }}>
          {data.team}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h2">{data.message}</Typography>
      </Grid>
      {data.bypass ? (
        <Grid item>
          <Typography variant="h6" style={{ color: "red" }}>
            This team has already completed this subtask and will be bypassing
            it.
          </Typography>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item>
        <Grid container spacing={2} alignItems="center" justify="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => dispatch(sendBeginDemo())}
            >
              {data.bypass ? "Team Arrived" : "Begin Demo"}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              disabled={data.time > 0}
              onClick={() => dispatch(didNotArrive())}
            >
              Did Not Arrive
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
